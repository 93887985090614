import React from "react"
// import { graphql } from "gatsby"
// import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import styles from "./torneo.module.css"
// import ContactSection from '../components/sections/contact'
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
// import Drop from '../components/drop-down'
import myImage from '../images/success.png'

const RetoPage2 = ({ data }) => {

  const urlRegisterAPI = "";
  // const urlRegisterAPI = "https://api.vikidz.io/teacher/up";
  const students_option = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19, 20, 21, 22, 23, 24 ,25]
  const onSubmit = async (values) => {
      const formTeacher = {
        "full_name": values.full_name,
        "email": values.email,
        "school": values.school,
        "school_year": values.school_year,
        "student_count": values.student_count,
        "country": values.country,
        "phone_number": values.telephone
      }
      axios.post(urlRegisterAPI, formTeacher).then(res => {
        //alert("Se ha registrado exitosamente");
        Swal.fire({
        title: '<span style="color:#37005E"><strong>¡Muy Bien!<strong><span>',
        iconColor: '#37005E',
        html: '<span style="color:#000000"><strong>Te registraste con éxito.<br> Espera muy pronto el lanzamiento de la app.<strong><span>',
        confirmButtonColor: '#00B200',
        confirmButtonText: 'Entendido',
        focusConfirm: false,
        width: 500,
        allowOutsideClick: false,
        imageUrl: myImage,
        imageHeight: 250,
      }).then(
        function () { 
          console.log("Redireccion");
          window.location.href = "/torneo";
        
        });
      }).catch((err) => {
        //TODO: replace the alert by sweetalerts
        const {error} = err.response.data
        let msg = ""
        switch(error){
          case "email_taken":
            Swal.fire({
              icon: 'error',
              title: 'Parace que el registro ha caducado',
              text: '',
            });
            msg = "oh"
            break;
            default:
            Swal.fire({
              icon: 'error',
              title: 'Parace que el registro ha caducado',
              text: '',
            });
            msg = "Ha habido un error al momento de registrarte para el torneo"
            break;
        }

        //alert(msg)
      });
  };

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const SignupSchema = Yup.object().shape({
    school: Yup.string()
      .required('Requerido'),
    student_count: Yup.number().required('Requerido').positive().integer(),
    school_year: Yup.number().required('Requerido').positive().integer(),
    city: Yup.string()
      .required('Requerido'),
    country: Yup.string()
      .required('Requerido'),
    full_name: Yup.string()
    .required('Requerido'),
    telephone: Yup.string()
      .matches(phoneRegExp, 'El número telefonico es inválido')
      .min(10, 'Deben de ser 10 dígitos')
      .max(10, 'Deben de ser 10 dígitos')
      .required('Requerido'),
    email: Yup.string().email('Correo inválido').required('Requerido'),
  });

  return (
    <Layout isRetoPage={false}>
      <SEO
        lang="es"
        title="Torneo de Mate Vikidz 2021"
        description="Torneo de Mate Vikidz 2021"
      />

      {/* <section className={styles.sectionLanding}>
        <div className="custom-container pt-32 pb-0 text-white">
          <Img
            alt="Logo reto"
            className="w-48 mx-auto"
            fluid={data.logo.childImageSharp.fluid}
          />

           <p className="mt-16">
          Es el torneo que rompe la barrera de la práctica de matemáticas motivando a los profesores y estudiantes de educación primaria en los colegios de México a practicar de una manera divertida mientras compiten por premios.
        </p> 
          <p className="mt-16">
            Lorem ipsum dolor sit amet consectetur adipiscing elit nam primis nisl ac praesent, phasellus aliquam maecenas iaculis himenaeos felis parturient tempus odio tortor auctor. Elementum facilisi litora arcu, est mus.
        </p>

          <p className="mt-6">
          LA MISIÓN: Becar a 350 niños al Centro Educativo SEDAC.
        </p>
          <p className="mt-6">
            Lorem ipsum dolor sit amet consectetur adipiscing
        </p>
        <img className={styles.imageHeader} src={imageHeader} alt="full_name" />

        <div className="row">
          <div className="col-4">
            <p className={styles.headText}>
              ¡Habrá 6 grupos ganadores!
              </p>
            <p className="mt-5">
              Podrás ver tu posición en las tablas de líderes durante el torneo en el siguiente enlace:         
            </p>
            <div className="my-4">
              <a href="#leader">
                <input className={styles.leaderButton} type="image" src={leaderOff} />
                <img className={styles.buttonLeaderImage} src="./" alt="LeaderBoard" />
              </a>
            </div>
            <div className="my-8 px-8 py-4 border-2 border-yellow-dark text-yellow-dark text-sm">
            <span className="block font-bold uppercase"> Lorem ipsum</span>
          Lorem ipsum dolor sit amet consectetur adipiscing elit, magna pulvinar urna non senectus consequat neque, laoreet id risus mi integer convallis. Est duis class purus vestibulum vehicula lacus nec congue et tincidunt, quis elementum scelerisque aliquet blandit sapien nam placerat accumsan, lectus a etiam tellus leo aenean sagittis cursus. <br /><br /> Tortor bibendum turpis dictumst metus erat facilisi rhoncus euismod, penatibus suscipit primis fermentum mus montes feugiat nullam faucibus, dignissim gravida hac ultrices platea ad viverra.
        </div>

          <p className="mt-6 text-lg font-bold">
            Descargas
        </p>

          <div className="my-4 flex flex-wrap">
            <div className={badgeGroupClasses}>
              <a href="/torneo/kit-competidor.zip" className={badgeClasses} download>
                <img className="block" src={kitBadge} alt="Kit Competidor" />
              </a>

              <a href="/torneo/bases.pdf" className={badgeClasses} download>
                <img className="block" src={basesBadge} alt="Bases" />
              </a>
            </div>

            <div className={badgeGroupClasses}>
              <a
                href="https://drive.google.com/file/d/1VZI4EADtnHGjlBifTowi0peBAvtOtSQW/view"
                target="_blank"
                rel="noreferrer noopener"
                className={badgeClasses}
              >
                <img className="block" src={macBadge} alt="Instalador Mac" />
              </a>

              <a
                href="https://drive.google.com/file/d/1f6hrnR_mMs1--42ZpC-US9hJV_mCxswl/view"
                target="_blank"
                rel="noreferrer noopener"
                className={badgeClasses}
              >
                <img className="block" src={macLowResBadge} alt="Instalador Mac - Baja resolución" />
              </a>
            </div>

            <div className={badgeGroupClasses}>
              <a
                href="https://drive.google.com/file/d/1Tt-1nrToIVjxaTyMcRuWhuJQUim97Q1_/view"
                target="_blank"
                rel="noreferrer noopener"
                className={badgeClasses}
              >
                <img className="block" src={winBadge} alt="Instalador Windows" />
              </a>

              <a
                href="https://drive.google.com/file/d/1EHDJWeMIOCWsgD4f3TjhQwQLC9tC6170/view"
                target="_blank"
                rel="noreferrer noopener"
                className={badgeClasses}
              >
                <img className="block" src={winLowResBadge} alt="Instalador Windows - Baja resolución" />
              </a>
            </div>

            <div className={badgeGroupClasses}>
              <a
                href="https://apps.apple.com/us/app/torneo-vikidz/id1500529123?ls=1"
                target="_blank"
                rel="noreferrer noopener"
                className={badgeClasses}
              >
                <img className="block" src={appStoreBadge} alt="App iOS" />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=io.vikidz.challenge"
                target="_blank"
                rel="noreferrer noopener"
                className={badgeClasses}
              >
                <img className="block" src={playStoreBadge} alt="App Android" />
              </a>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <a className="w-1/3 p-2 inline-block underline" href="/torneo/aviso-de-privacidad-torneo-vikidz.pdf" download>
              Aviso de privacidad
          </a>

            <a className="w-1/3 p-2 inline-block underline" href="/torneo/guia-de-instalacion-mac.pdf" download>
              Guía de instalación<br />Mac
          </a>

            <a className="w-1/3 p-2 inline-block underline" href="/torneo/guia-de-instalacion-pc.pdf" download>
              Guía de instalación<br />Windows
          </a>
          </div>
        </div>
      </section> */}

      {/* <Leaderboard /> */}
      <script src="//cdn.jsdelivr.net/npm/sweetalert2@10"></script>
      <section className="bg-form-purple py-32 text-reg-form">
      {/* <section className={styles.sectionRegister}> */}
        <div className="custom-container">
          <h3 className="subheading">Registro</h3>

          <p className="mb-8">Registra tus salones para competir en el torneo ahora mismo.</p>
          <Formik initialValues={{
              school: '',
              student_count: undefined,
              school_year: '',
              city: '',
              full_name: '',
              email: '',
              telephone: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={onSubmit}>
              {({ errors, touched, handleSubmit }) => (
            <form name="reto" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
              <input type="hidden" name="form-name" value="reto" />

              <div className="hidden">
                <label>
                  Don’t fill this out if you're human:
              <input type="hidden" name="bot-field" />
                </label>
              </div>

              <div className="form-field">
                <label className="form-label text-blue-darkest">Colegio</label>
                <span className="form-label-help block text-blue-darker">Escribe el nombre del Colegio y/o su página web</span>
                <Field name="school" className="form-control"/>
                {errors.school && touched.school ? (
                  <div>{errors.school}</div>
                ) : null}
                {/* <input className="form-control" autocomplete="off" name="colegio" type="text" onChange={handleInputChange} name="school" /> */}
              </div>

              <div className="flex flex-wrap md:flex-no-wrap">
                <div className="form-field md:mr-4 w-full md:w-3/6">
                  <label className="form-label text-blue-darkest">Número de alumnos</label>
                  <Field name="student_count" className="form-control">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <div className="pt-3 text-purple-dark" id="App">
                      <div className="select-container">
                        <select className="form-control" placeholder="Email" {...field} >
                        {students_option.map((value) => {
                            return <option key={value} value={value}>{value}</option>;
                        })}
                        </select>
                        {/* {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )} */}
                      </div>
                    </div>
                  )}
                  </Field>
                    {errors.student_count && touched.student_count ? (
                      <div>{errors.student_count}</div>
                    ) : null}
                  {/* <input className="form-control" autocomplete="off" name="alumnos" type="number" onChange={handleInputChange} name="student_count" /> */}
                </div>
                <div className="form-field md:mr-4 w-full md:w-3/12">
                  <label className="form-label text-blue-darkest">Grado</label>
                  <Field name="school_year" className="form-control">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <div className="pt-3 text-purple-dark" id="App">
                      <div className="select-container">
                        <select className="form-control" placeholder="Email" {...field} >
                        {[0,1,2,3,4,5,6].map((value) => {
                            return <option key={value} value={value}>{value}</option>;
                        })}
                        </select>
                        {/* {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )} */}
                      </div>
                    </div>
                  )}
                  </Field>
                </div>
                <div className="form-field w-full md:w-3/12">
                  <label className="form-label text-blue-darkest">Ciudad</label>
                  <Field name="city" className="form-control"/>
                  {errors.city && touched.city ? (
                    <div>{errors.city}</div>
                  ) : null}
                  {/* <input className="form-control" autocomplete="off" name="ciudad" type="text" onChange={handleInputChange} name="city" /> */}
                </div>
                <div className="form-field md:ml-4 w-full md:w-3/12">
                  <label className="form-label text-blue-darkest">Pais</label>
                  <Field name="country" className="form-control"/>
                  {errors.country && touched.country ? (
                    <div>{errors.country}</div>
                  ) : null}
                  {/* <input className="form-control" autocomplete="off" name="ciudad" type="text" onChange={handleInputChange} name="city" /> */}
                </div>
              </div>

              <div className="form-field w-full md:w-2/3">
                <label className="form-label text-blue-darkest">Nombre del profesor representante</label>
                <Field name="full_name" className="form-control"/>
                  {errors.full_name && touched.full_name ? (
                    <div>{errors.full_name}</div>
                  ) : null}
                {/* <input className="form-control" autocomplete="off" onChange={handleInputChange} name="full_name" type="text" /> */}
              </div>

              <div className="flex flex-wrap md:flex-no-wrap">
                <div className="form-field flex flex-col justify-between md:mr-4 w-full md:w-1/2">
                  <label className="form-label text-blue-darkest">Email</label>
                  <Field name="email" className="form-control"/>
                  {errors.email && touched.email ? (
                    <div>{errors.email}</div>
                  ) : null}
                  {/* <input className="form-control" autocomplete="off" name="email" type="email" onChange={handleInputChange} name="email" /> */}
                </div>

                <div className="form-field w-full md:w-1/2">
                  <label className="form-label text-blue-darkest">Teléfono</label>
                  <span className="form-label-help block text-blue-darker">Incluye LADA</span>
                  <Field name="telephone" className="form-control"/>
                  {errors.telephone && touched.telephone ? (
                    <div>{errors.telephone}</div>
                  ) : null}
                  {/* <input className="form-control" autocomplete="off" name="telefono" type="tel" onChange={handleInputChange} name="telephone" /> */}
                </div>
              </div>
              <div className="last-div">Revisa tus datos antes de enviarlos,<br/>después de registrarte no podrás hacer cambios</div>

              <button type="submit" className="btn btn--blue mt-2">Registrarse</button>
            </form>)}
          </Formik>
        </div>
      </section>
    </Layout>
  )
}

export default RetoPage2


